import React, {useState} from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Collapse from 'react-bootstrap/Collapse';
import "./Welcome.css";
import Projects from "./Projects";
import Contact from "./Contact"

function Welcome() {

    const [activeTab, setActiveTab] = useState("home");

    const handleTabChange = (tab) => {
        let gradient;
        if (tab === "home") {
          gradient = "linear-gradient(45deg, #c31432, #240b36)";//#59C173, cyan, #5D26C1
        } else if (tab === "projects") {
          gradient = "linear-gradient(45deg, #360033, #0b8793)";
        } else if (tab === "links") {
          gradient = "linear-gradient(45deg, #005AA7, #FFFDE4)";
        } else if (tab === "contact") {
          gradient = "linear-gradient(45deg, #DAE2F8, #D6A4A4)";
        }
      
        // Set the gradient as a CSS variable
        document.documentElement.style.setProperty("--gradient", gradient);
      };
    

    return (
        <Container fluid className="container">
            <div className="centered-content">
                <h1 className="name-header">Elijah Kamolvathin</h1>
                <h3 className="title-header">Software Developer</h3>
                <hr className="rainbow-line"></hr>
                <Navbar expand="sm" className="navbar">
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                    <Nav.Link onClick={() => {setActiveTab("home"); handleTabChange("home");}}>HOME</Nav.Link>
                    <Nav.Link onClick={() => {setActiveTab("projects"); handleTabChange("projects");}}>PROJECTS</Nav.Link>
                    <Nav.Link onClick={() => {setActiveTab("links"); handleTabChange("links");}}>LINKS</Nav.Link>
                    <Nav.Link onClick={() => {setActiveTab("contact"); handleTabChange("contact");}}>CONTACT</Nav.Link>
                    </Navbar.Collapse>
                </Navbar>
                <Collapse in={activeTab === 'home'}>
                    <div className="content about-me">
                        Hi, I'm Elijah! I'm a full-stack developer with a passion for all things tech. I'm a high-performing computer science student and I recently completed Thinkful's software engineering bootcamp.
                        I'm proficient with JavaScript for both front-end and back-end using the PERN stack. I'm always excited to learn new technologies to build upon my tech-skills repertoire. I also love connecting with new people,
                        so I invite you to reach out<span className="different-color">!</span>
                    </div>
                </Collapse>
                <Collapse in={activeTab === 'projects'}>
                    <div>
                        <Projects />
                    </div>
                </Collapse>
                <Collapse in={activeTab === 'links'}>
                    <div className="content">
                    <a href="https://github.com/OffChickens">
                        <button className="btn btn-primary"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-github" viewBox="0 0 16 16">
                            <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"/>
                            </svg>
                        </button>
                    </a>
                    <a href="https://www.linkedin.com/in/elijah-kamolvathin">
                        <button className="btn btn-primary"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                            <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
                            </svg>
                        </button>
                    </a>
                    </div>
                </Collapse>
                <Collapse in={activeTab === 'contact'}>
                    <div className="content">
                        <Contact />
                    </div>
                </Collapse>
            </div>
        </Container>
    )
}

export default Welcome;