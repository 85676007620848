import React, { useState,} from "react";
import { createContact } from "./utils/api";
import Container from "react-bootstrap/Container";
import "./Welcome.css";

function Contact() {
  const [errors, setErrors] = useState([]);
  const initialFormState = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    message: "",
  };

  const [formData, setFormData] = useState(initialFormState);
  const [buttonText, setButtonText] = useState("Send");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setErrors([]);
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validMobile = (number) => {
    return /^(?:\d{3}-\d{3}-\d{4}|\d{10})$/.test(number);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    const requiredFields = ['first_name', 'last_name', 'email', 'phone', 'message'];
    const missingFields = requiredFields.filter(field => !formData[field]);
  
    if (missingFields.length > 0) {
      return setErrors([`Please fill in all required fields: ${missingFields.join(', ')}.`]);
    }

    if (!validMobile(formData.phone)) {
      return setErrors(["Invalid phone number format. It should be a 10-digit number with or without dashes."])
    }

    if (errors.length === 0) {
      try {
        await createContact(formData);
        setButtonText("Sent!");
        setFormData(initialFormState);
      } catch (error) {
        console.error("Error creating contact:", error);
      }
    } else {
      console.log(errors.length)
      setErrors(errors.map(error => (error.message)));
    }
  };

  return (
    <Container fluid className="form-container">
      <form onSubmit={handleSubmit}>
        <div className="form_item">
          <div className="side-by-side">
            <div className="individual-item">
              <input
                className="form_input"
                type="text"
                name="first_name"
                id="first_name"
                value={formData.first_name}
                placeholder="First Name"
                onChange={handleChange}
              />
            </div>
            <div className="individual-item">
              <input
                className="form_input"
                type="text"
                name="last_name"
                id="last_name"
                placeholder="Last Name"
                value={formData.last_name}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="form_item">
          <div className="side-by-side">
            <div className="individual-item">
              <input
                className="form_input"
                type="text"
                name="phone"
                id="phone"
                placeholder="#"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>
            <div className="individual-item">
              <input
                className="form_input"
                type="text"
                name="email"
                id="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="form_item">
          <textarea
            className="area-text"
            name="message"
            id="message"
            placeholder="Message"
            value={formData.message}
            onChange={handleChange}
          />
        </div>
        <div className="form_item">
          <div>
            <button className="form_btn submit_btn" type="submit">
              {buttonText}
            </button>
          </div>
        </div>
      </form>
      {errors.length > 0 && (
                <div className="alert alert-danger">
                    {errors.map((error, index) => (
                        <p key={index}>{error}</p>
                    ))}
                </div>
        )}
    </Container>
  );
}

export default Contact;
