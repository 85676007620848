import React, { useState } from "react";
import Nav from 'react-bootstrap/Nav';
import Collapse from 'react-bootstrap/Collapse';
import "./Welcome.css";
import { Card, Container } from "react-bootstrap";

function Projects() {
    const [activeProject, setActiveProject] = useState("");

    const handleProjectClick = (projectName) => {
        if (activeProject === projectName) {
            // If the clicked project is already active, close it immediately
            setActiveProject("");
        } else {
            // If a different project is clicked, close the current one first, and then open the new one
            setActiveProject(""); // Close the current project
            setTimeout(() => {
                setActiveProject(projectName); // Open the new project after a brief delay
            }, 300); // Adjust the delay time as needed for a smooth transition
        }
    };

    return (
        <Container fluid className="projects-container container">
            <div className="project-list">
                <div className="project-buttons">
                    <Nav.Link onClick={() => handleProjectClick("project1")} className="project-button">
                        Periodic Tables
                    </Nav.Link>
                    <Nav.Link onClick={() => handleProjectClick("project2")} className="project-button">
                        We Love Movies
                    </Nav.Link>
                    <Nav.Link onClick={() => handleProjectClick("project3")} className="project-button">
                        Solar System Sim
                    </Nav.Link>
                    <Nav.Link onClick={() => handleProjectClick("project4")} className="project-button">
                        Flashcard-o-matic
                    </Nav.Link>
                </div>
                <div className="project-details">
                    <Collapse in={activeProject === "project1"} dimension="width">
                        <div className="details">
                            <Card body style={{ width: '600px' }}>A full-stack restaurant reservation system created using React, Express, Node and PostgreSQL. 
                            The user is able to create and edit reservations, create tables and seat reservations at them, search for reservations by phone number, and more! 
                            It was a fun project that was an enjoyable way to put everything I learned at Thinkful together.
                            </Card>
                            <a href="https://github.com/OffChickens/Periodic-Tables">
                                <button className="btn btn-primary"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-github" viewBox="0 0 16 16">
                                    <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"/>
                                    </svg>
                                </button>
                            </a>
                        </div>
                    </Collapse>
                    <Collapse in={activeProject === "project2"} dimension="width">
                        <div className="details">
                            <Card body style={{ width: '600px' }}>A backend project in which I set up a database and created specific routes to allow users to gain access to data about movies, theaters and reviews in interesting ways. 
                            The API was built following RESTful design principles. 
                            This project helped me to realize that I really enjoy working with the backend side of applications! 
                            Putting all the puzzle pieces together in a way that works is something I can't get enough of.</Card>
                            <a href="https://github.com/OffChickens/We-Love-Movies">
                                <button className="btn btn-primary"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-github" viewBox="0 0 16 16">
                                    <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"/>
                                    </svg>
                                </button>
                            </a>
                        </div>
                    </Collapse>
                    <Collapse in={activeProject === "project3"} dimension="width">
                        <div className="details">
                            <Card body style={{ width: '600px' }}>An interactive solar system simulation using C++ and OpenGL. 
                            I built out a starry skybox with planets rotating around the sun where the user can move around and take a closer look at the textured, scaled down planets. 
                            As the final project for a class, this cemented that class as my favorite!
                            </Card>
                            <a href="https://github.com/OffChickens/OpenGL-Solar-System">
                                <button className="btn btn-primary"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-github" viewBox="0 0 16 16">
                                    <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"/>
                                    </svg>
                                </button>
                            </a>
                        </div>

                    </Collapse>
                    <Collapse in={activeProject === "project4"} dimension="width">
                        <div className="details">
                            <Card body style={{ width: '600px' }}>A frontend project in which I built a flash card application with React to help users study. 
                            The app allows users to create and edit decks or study the decks available.
                            This project was nice way to introduce myself to Bootstrap and play around with some of the capabilities of React.
                            It also had some great practical use afterward.</Card>
                            <a href="https://github.com/OffChickens/Flashcard-o-matic">
                                <button className="btn btn-primary"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-github" viewBox="0 0 16 16">
                                    <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"/>
                                    </svg>
                                </button>
                            </a>
                        </div>
                    </Collapse>
                </div>
            </div>
        </Container>
    )
}

export default Projects;